import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 4320.000000 4320.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,4320.000000) scale(0.100000,-0.100000)">
          <path d="M15444 22205 c0 -13 5 -21 11 -18 7 2 17 -3 24 -11 11 -13 9 -14 -13
-10 -20 3 -26 0 -26 -14 0 -14 7 -17 35 -17 40 2 56 12 36 24 -11 7 -9 10 7
15 18 6 20 10 9 27 -7 12 -21 18 -37 17 -14 -1 -30 1 -36 4 -6 4 -10 -3 -10
-17z m46 -7 c0 -4 7 -5 15 -2 8 4 15 1 15 -5 0 -15 -20 -13 -50 4 -24 13 -24
14 -2 12 12 -1 22 -5 22 -9z"/>
<path d="M15550 22171 c0 -13 56 -31 96 -31 18 0 34 -4 36 -8 2 -5 33 -23 71
-40 67 -32 167 -45 167 -22 0 6 -9 10 -19 10 -11 0 -23 5 -26 10 -10 17 -45
12 -39 -5 4 -8 1 -15 -5 -15 -6 0 -11 5 -11 10 0 6 -7 10 -15 10 -8 0 -12 6
-9 14 4 11 0 13 -17 9 -17 -5 -20 -3 -14 7 5 8 4 11 -2 7 -14 -9 -74 13 -67
24 3 5 -11 9 -31 9 -20 0 -34 4 -31 8 3 5 -15 9 -40 11 -24 1 -44 -2 -44 -8z"/>
<path d="M15445 22122 c-3 -3 -5 -540 -5 -1194 l0 -1188 504 0 c404 0 521 3
592 15 383 65 613 270 712 635 l26 95 3 518 c3 508 3 517 -16 517 -11 0 -22 4
-25 9 -4 5 -49 19 -101 32 -52 13 -95 27 -95 31 0 4 -8 8 -18 8 -10 0 -22 4
-27 9 -6 5 -39 16 -75 25 -36 9 -68 20 -72 24 -4 4 34 6 85 5 50 -2 102 -3
114 -3 11 0 24 -7 27 -15 3 -9 19 -15 40 -15 27 0 34 3 30 15 -4 9 -19 15 -39
15 -18 0 -39 8 -49 19 -24 26 -82 34 -111 15 -18 -12 -46 -15 -110 -13 -76 3
-88 6 -94 23 -5 13 -10 16 -15 8 -11 -17 -30 -15 -56 8 l-23 20 36 0 c30 0 37
4 37 20 0 21 -15 27 -25 10 -3 -5 -17 -10 -30 -10 l-24 0 -3 -582 c-4 -577 -4
-584 -26 -644 -58 -156 -150 -204 -394 -204 l-138 0 -2 798 c-3 739 -4 797
-20 797 -9 0 -20 4 -24 8 -4 4 -17 7 -28 7 -12 0 -32 9 -46 20 l-25 20 46 0
c32 0 49 5 52 15 7 17 -8 29 -25 19 -6 -4 -8 -3 -4 4 8 14 0 19 -47 27 -41 8
-52 -8 -14 -20 l22 -8 -22 -4 c-13 -2 -23 -11 -23 -19 0 -16 -22 -18 -48 -5
-9 5 -35 16 -57 25 l-40 15 33 0 c17 1 32 6 32 11 0 6 -18 10 -40 10 -22 0
-40 -4 -40 -9 0 -10 -48 -2 -56 10 -3 5 9 9 25 9 33 0 42 14 16 24 -35 13
-145 19 -145 7 0 -6 17 -11 39 -11 22 0 42 -4 45 -8 8 -13 -87 4 -119 21 l-30
17 33 0 c17 0 32 5 32 10 0 10 -145 12 -155 2z m1275 -462 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M16028 21973 c-35 -5 -37 -22 -3 -24 14 -1 31 -2 38 -3 14 -1 17 27
2 29 -5 1 -22 0 -37 -2z"/>
<path d="M16640 21820 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M16660 21794 c-24 -14 -24 -14 20 -13 25 1 55 -2 68 -6 14 -5 22 -3
22 4 0 6 -3 10 -7 10 -20 -3 -34 2 -28 11 9 15 -46 10 -75 -6z"/>
<path d="M16753 21760 c-32 -13 -29 -20 6 -16 19 2 32 -2 35 -10 3 -8 13 -14
23 -14 16 1 16 2 -2 17 -19 15 -18 15 14 9 18 -3 41 -6 50 -6 14 -1 14 -2 -1
-14 -16 -12 -16 -14 7 -24 29 -13 85 2 69 18 -9 9 -158 51 -173 49 -3 0 -16
-4 -28 -9z m175 -39 c11 -7 11 -9 -2 -14 -20 -8 -48 2 -40 14 7 11 25 11 42 0z"/>
<path d="M11510 21720 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M11566 21695 c4 -8 10 -12 15 -9 5 3 9 0 9 -6 0 -6 4 -9 9 -6 5 3 14
-1 21 -9 14 -17 35 -21 25 -5 -3 6 -1 10 4 10 6 0 11 5 11 11 0 6 -7 8 -16 5
-9 -3 -25 1 -37 9 -28 19 -48 19 -41 0z"/>
<path d="M11667 21673 c-12 -19 2 -39 43 -62 l39 -21 6 21 c4 11 13 18 20 15
7 -2 31 -10 54 -17 23 -7 41 -18 41 -27 0 -9 6 -12 16 -8 9 3 19 6 23 6 4 0 7
9 8 20 2 17 -4 20 -38 20 -21 0 -39 5 -39 11 0 6 -12 8 -32 5 -22 -4 -27 -3
-14 2 19 8 18 9 -5 15 -40 11 -46 8 -41 -18 5 -28 -9 -33 -32 -10 -8 8 -19 15
-25 15 -5 0 -11 10 -13 23 -2 14 -6 18 -11 10z"/>
<path d="M11690 21658 c0 -6 3 -9 6 -5 4 3 15 -4 25 -16 15 -19 18 -19 19 -5
0 10 -9 22 -19 28 -25 13 -31 13 -31 -2z"/>
<path d="M17200 21621 c0 -7 58 -41 71 -41 5 0 9 6 9 13 0 7 -18 18 -40 24
-22 5 -40 7 -40 4z"/>
<path d="M11760 21592 c0 -19 110 -54 144 -46 17 4 37 7 44 5 6 -1 12 5 12 12
0 8 7 18 16 21 22 8 26 8 19 -4 -4 -6 1 -7 11 -3 13 5 13 7 3 13 -19 10 -64 7
-80 -6 -26 -21 -50 -24 -72 -10 -29 19 -97 31 -97 18z"/>
<path d="M17120 21590 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10
-22 0 -40 -4 -40 -10z"/>
<path d="M17675 21453 c-4 -10 -9 -27 -11 -38 -4 -22 -200 -1112 -262 -1457
l-38 -218 322 0 322 0 11 68 c15 92 111 752 111 763 0 5 128 9 290 9 160 0
290 -2 290 -5 0 -13 122 -830 124 -833 2 -1 149 -1 328 0 l324 3 -90 511 c-57
321 -97 518 -106 529 -10 13 -11 16 -2 11 10 -6 13 -2 10 15 -2 19 -8 24 -31
22 -17 -1 -25 2 -21 8 4 7 -1 7 -14 0 -16 -9 -23 -8 -29 2 -4 8 -21 19 -38 24
l-30 10 36 4 c26 3 35 1 32 -7 -3 -8 11 -13 40 -15 46 -4 57 14 15 24 -15 4
-19 11 -15 26 3 12 1 19 -5 15 -5 -3 -8 2 -6 11 2 10 -2 20 -8 22 -7 2 -11 -6
-10 -22 0 -14 4 -25 9 -25 4 0 7 -4 7 -9 0 -6 -12 -6 -30 -1 -16 5 -38 6 -49
3 -12 -3 -28 3 -41 15 -26 24 -70 35 -70 16 0 -7 16 -18 35 -24 40 -13 52 -26
25 -26 -10 0 -16 4 -13 9 3 5 -4 6 -16 2 -12 -4 -21 -2 -21 4 0 6 -8 11 -19
11 -10 0 -24 7 -31 15 -7 8 -22 15 -34 15 -11 0 -28 5 -36 11 -23 16 164 2
228 -17 12 -3 22 -3 22 0 0 7 -41 25 -51 22 -3 0 -14 6 -24 14 -11 8 -16 10
-11 3 5 -9 2 -11 -8 -7 -9 3 -13 11 -9 18 4 6 -2 4 -12 -4 -11 -9 -16 -10 -11
-2 5 8 -2 15 -21 22 -15 5 -41 16 -56 25 -16 8 -39 15 -52 15 -13 0 -26 4 -30
10 -3 5 -12 7 -19 4 -7 -3 -16 2 -19 11 -4 9 -17 15 -34 14 -34 -3 -103 26
-103 41 0 8 -13 10 -41 5 -28 -5 -39 -4 -35 3 11 18 -13 24 -35 9 -20 -14 -19
-15 12 -26 18 -6 47 -11 65 -11 19 0 34 -4 34 -9 0 -4 17 -15 38 -24 l37 -16
-79 6 -78 6 7 -24 c7 -22 7 -22 -9 -6 -19 18 -101 57 -122 57 -8 0 -14 5 -14
10 0 15 29 12 46 -5 17 -17 94 -21 94 -5 0 6 -18 10 -40 10 -22 0 -40 5 -40
10 0 6 5 10 10 10 6 0 10 7 10 16 0 13 -14 15 -112 9 -62 -3 -142 -2 -178 2
-59 7 -65 10 -64 31 1 12 -4 22 -10 22 -6 0 -4 5 4 10 13 8 12 10 -2 10 -11 0
-18 7 -18 18 0 13 2 14 9 3 5 -7 14 -11 20 -7 14 9 10 58 -4 52 -5 -2 -9 -9
-7 -15 2 -8 -6 -7 -20 3 -26 19 -38 20 -38 5 0 -5 4 -8 8 -5 5 3 9 0 9 -7 0
-7 2 -16 3 -22 9 -36 0 -43 -35 -32 -41 12 -46 27 -10 27 14 0 25 -5 25 -11 0
-5 5 -7 10 -4 7 4 7 13 0 26 -6 11 -15 17 -21 14 -12 -8 -12 2 1 26 9 16 7 21
-14 28 -13 6 -26 15 -28 22 -3 8 -9 7 -21 -5 -14 -14 -20 -15 -49 -3 -19 7
-48 10 -68 7 l-35 -6 30 -7 c17 -4 49 -13 72 -21 39 -12 43 -12 48 5 8 25 22
24 30 -1 4 -11 11 -17 17 -14 7 4 8 2 4 -5 -4 -7 -17 -9 -29 -6 -20 5 -20 5 2
-8 23 -14 23 -14 -14 -20 -41 -7 -95 1 -95 13 0 7 -25 13 -145 37 -16 3 -32 9
-35 12 -12 14 -68 39 -102 45 -21 4 -38 10 -39 14 0 4 -2 15 -4 25 -2 12 3 17
21 17 13 0 24 -4 24 -10 0 -5 9 -10 20 -10 11 0 20 -6 20 -14 0 -12 4 -12 30
1 23 11 37 12 58 5 17 -7 21 -11 10 -11 -10 -1 -18 -5 -18 -11 0 -12 22 -9 31
4 4 4 5 16 2 25 -3 13 -24 21 -71 31 -37 7 -76 19 -87 26 -25 18 -31 18 -40
-3z m32 -19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m56 -11
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m765 -290 c-37 -3 -48
-8 -48 -21 0 -15 -2 -15 -10 -2 -9 13 -11 13 -20 0 -9 -13 -11 -13 -20 0 -9
13 -11 13 -20 0 -8 -13 -10 -13 -10 2 0 18 36 25 130 24 l45 0 -47 -3z m-238
-17 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m560 -70 c0 -16 31 -26 78 -26 17 0 32 -4 32 -9 0 -4 17 -16 38 -24 l37 -16
-74 5 c-49 4 -76 3 -80 -5 -5 -8 -20 -5 -49 9 -22 11 -48 20 -56 20 -8 0 -16
5 -18 10 -2 6 -22 12 -46 13 -62 2 -51 17 14 17 34 0 53 -4 49 -10 -10 -16 15
-12 32 5 8 8 20 12 26 8 7 -4 8 0 4 11 -4 10 -3 15 3 11 6 -3 10 -12 10 -19z
m-290 10 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m-61 -10 c-2 -2 -15 -9 -29 -15 -24 -11 -24 -11 -6 3 16 13 49 24 35 12z
m421 -156 c-8 -5 -26 -9 -40 -9 l-25 1 25 8 c14 4 32 8 40 9 13 1 13 0 0 -9z"/>
<path d="M18800 21010 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10
-22 0 -40 -4 -40 -10z"/>
<path d="M17893 21383 c3 -13 0 -23 -6 -24 -7 0 -3 -5 8 -10 17 -8 16 -9 -7
-6 -26 4 -57 -7 -48 -16 3 -3 20 -8 38 -12 41 -9 51 12 27 58 -11 22 -15 25
-12 10z"/>
<path d="M17913 21384 c0 -8 6 -14 14 -14 7 0 13 4 13 9 0 5 -6 11 -13 14 -8
3 -14 -1 -14 -9z"/>
<path d="M17968 21363 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M18000 21290 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>
<path d="M19175 20954 c11 -8 19 -22 17 -31 -2 -8 1 -12 7 -8 6 3 11 10 11 15
0 13 -30 40 -43 40 -7 0 -3 -7 8 -16z"/>
<path d="M19260 20908 c0 -12 3 -18 7 -14 11 10 12 36 2 36 -5 0 -9 -10 -9
-22z"/>
<path d="M19920 20700 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M19940 20674 l-25 -14 27 0 c32 0 58 9 58 21 0 13 -31 9 -60 -7z"/>
<path d="M19951 20653 c-31 -4 -33 -6 -29 -38 3 -27 1 -32 -9 -23 -11 8 -13
-69 -13 -421 l0 -431 325 0 325 0 0 355 c0 230 -4 355 -10 355 -5 0 -10 -7
-10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 12 -10 26 0 21 4 24 20 19 16 -5 20 -2
20 14 0 12 -4 21 -10 21 -5 0 -7 -7 -4 -15 8 -20 -25 -9 -47 17 -17 19 -89 26
-89 8 0 -5 18 -10 41 -10 31 0 40 -3 36 -14 -3 -8 1 -17 9 -20 8 -3 14 -17 14
-32 0 -20 -3 -24 -12 -16 -7 5 -24 12 -38 15 -25 4 -25 4 3 6 15 0 27 6 27 12
0 8 -17 10 -55 6 -30 -3 -57 -1 -60 4 -4 5 -16 9 -29 9 -19 0 -22 4 -18 30 5
33 -8 39 -33 14 -11 -12 -25 -14 -50 -10 -19 4 -35 11 -35 16 0 5 -8 11 -17
13 -10 3 -1 5 20 6 20 0 37 -3 37 -9 0 -13 37 -13 42 -1 2 5 21 12 43 15 33 5
35 4 15 -4 -22 -9 -21 -9 9 -5 18 3 39 5 45 5 24 0 -21 20 -46 20 -16 0 -28 5
-28 10 0 6 -7 10 -15 10 -8 0 -15 -6 -15 -12 0 -9 -4 -8 -10 2 -7 12 -10 12
-10 1 0 -8 -6 -11 -15 -7 -25 9 -104 -5 -99 -18 3 -7 -10 -11 -33 -12 l-38 -1
30 14 30 13 -30 -2 c-34 -3 -115 21 -115 34 0 11 5 10 47 -7 40 -17 51 -15 30
7 -15 14 -6 29 23 41 12 4 11 6 -2 6 -10 1 -18 6 -18 11 0 10 -61 11 -129 3z
m96 -28 c0 -17 -6 -18 -26 -6 -8 5 -24 6 -37 1 -17 -7 -22 -6 -18 3 7 20 81
21 81 2z m-97 -15 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m34 -18 c3 -5 -1 -9 -9 -9 -8 0 -15 4 -15 9 0 4 4 8 9 8 6 0 12
-4 15 -8z m147 -110 c-7 -2 -18 1 -23 6 -8 8 -4 9 13 5 13 -4 18 -8 10 -11z"/>
<path d="M20105 20616 l-30 -13 40 -7 c22 -4 43 -12 46 -18 3 -5 17 -8 30 -6
l24 5 -25 12 c-25 11 -25 12 5 7 17 -3 38 -8 48 -11 10 -4 17 -2 17 5 0 6 -4
9 -9 6 -4 -3 -25 4 -47 14 -44 23 -58 24 -99 6z"/>
<path d="M21367 20214 c-4 -4 -7 -14 -7 -23 0 -14 2 -14 10 -1 8 12 10 12 10
1 0 -8 3 -12 6 -8 3 3 2 13 -3 22 -5 8 -12 12 -16 9z"/>
<path d="M21403 20174 c-3 -8 -14 -14 -24 -14 -10 0 -19 -4 -19 -9 0 -4 37
-12 82 -17 49 -6 83 -14 86 -21 2 -9 -10 -13 -42 -13 -28 0 -46 4 -46 12 0 6
-3 8 -7 4 -5 -4 1 -14 11 -22 11 -8 15 -14 9 -14 -5 0 -18 9 -28 20 -18 20
-54 23 -67 5 -4 -5 -8 -90 -8 -187 l0 -178 549 0 c382 0 550 3 553 11 2 6 47
11 118 12 63 0 106 3 95 5 -11 2 -22 7 -25 11 -8 10 -90 29 -90 21 0 -4 -7
-11 -16 -14 -8 -3 -12 -2 -9 4 4 6 0 17 -9 25 -9 9 -17 11 -21 5 -3 -5 -1 -10
4 -10 6 0 11 -6 11 -13 0 -8 -6 -14 -14 -14 -7 0 -13 5 -13 11 1 35 -4 45 -18
40 -8 -4 -15 -1 -15 5 0 6 -4 11 -10 11 -5 0 -10 -5 -10 -11 0 -6 -7 -17 -15
-25 -8 -9 -15 -11 -15 -6 0 10 -59 4 -75 -8 -5 -4 -23 -1 -40 6 l-30 13 33 1
c17 0 32 5 32 10 0 6 -9 10 -19 10 -11 0 -22 4 -25 8 -3 5 -53 12 -112 15 -75
5 -113 12 -124 22 -10 9 -35 18 -56 21 -65 9 -82 14 -96 27 -7 7 -35 20 -63
29 -46 15 -47 16 -18 17 17 1 33 7 36 14 3 10 13 10 50 0 26 -7 68 -13 93 -13
36 0 45 -3 41 -14 -4 -9 2 -18 16 -23 20 -6 18 -8 -13 -14 -29 -5 -24 -7 27
-8 34 0 65 -5 68 -11 4 -6 -5 -10 -19 -10 -14 0 -26 -4 -26 -10 0 -5 18 -10
40 -10 23 0 40 5 40 11 0 8 17 10 50 7 28 -3 53 -2 55 2 5 8 -62 30 -89 30 -9
0 -16 5 -16 10 0 6 -7 10 -16 10 -8 0 -13 -4 -10 -8 2 -4 -8 -9 -24 -10 -16
-1 -30 4 -33 11 -4 10 2 13 21 10 15 -2 8 3 -16 11 -23 8 -48 18 -55 24 -13
10 -61 21 -114 25 -18 2 -38 10 -45 19 -12 16 -74 36 -123 41 -11 1 -26 8 -33
15 -16 14 -82 17 -82 3 0 -13 30 -22 58 -16 15 2 25 -3 32 -16 6 -11 15 -17
21 -14 5 4 9 -1 9 -9 0 -11 -10 -16 -30 -16 -27 0 -30 2 -20 19 15 29 12 38
-5 15 -8 -10 -15 -27 -15 -36 0 -10 -1 -18 -3 -18 -2 0 -31 14 -65 30 -34 17
-69 30 -77 30 -8 0 -15 6 -15 13 0 9 9 12 29 9 16 -2 39 2 50 10 20 14 20 15
1 31 -17 15 -19 14 -23 -1 -3 -13 -5 -11 -6 5 -1 15 -11 25 -36 33 -19 6 -40
8 -46 4 -8 -4 -9 -3 -5 4 5 8 -1 12 -16 13 -13 0 -31 2 -41 4 -10 2 -21 -3
-24 -11z m144 -46 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m293
-103 c0 -11 -11 -15 -40 -15 -29 0 -40 4 -40 15 0 11 11 15 40 15 29 0 40 -4
40 -15z m143 -172 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z
m240 -20 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m177 -53 c0
-6 -11 -8 -27 -4 -40 10 -41 14 -5 14 18 0 32 -5 32 -10z"/>
<path d="M21643 20062 c-24 -2 -43 -7 -43 -13 0 -13 77 -11 85 2 9 14 7 14
-42 11z"/>
<path d="M21840 19970 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10
-22 0 -40 -4 -40 -10z"/>
<path d="M22260 19879 c-18 -6 -13 -7 20 -3 38 4 42 3 25 -7 -19 -12 -19 -12
0 -20 45 -17 63 0 28 27 -20 15 -37 16 -73 3z"/>
<path d="M22357 19851 c-3 -12 1 -22 10 -25 20 -8 33 1 33 22 0 15 -2 15 -10
2 -8 -13 -10 -12 -10 3 0 23 -16 22 -23 -2z"/>
<path d="M22518 19743 c23 -2 61 -2 85 0 23 2 4 4 -43 4 -47 0 -66 -2 -42 -4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
